<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>UoM Groups</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="accent" small to="/inventory/new-uom-group">
            <v-icon left dark>mdi-plus</v-icon>New
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="uomGroups"
                :search="search"
              >
                <template v-slot:item.actions="{ item }">
                  <v-btn v-show="item.UgpName !== 'Manual'" text :to="`/uom-group/definition/${item.id}`" color="accent">
                    <v-icon>mdi-pen-plus</v-icon>Group Definition
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- start of group dialog -->
      <v-dialog v-model="gdDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="accent">
            <v-toolbar-title>
              <v-btn icon dark @click="gdDialog = false">
                <v-icon dark>mdi-keyboard-backspace</v-icon>
              </v-btn>
              {{groupItem.UgpName}} - Group Definition
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="gdDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-btn @click="newGroup" color="primary" small>
                    <v-icon left>mdi-plus</v-icon>New Group Definition
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Alt Qty.</th>
                          <th class="text-left">Alt UoM</th>
                          <th class="text-left">=</th>
                          <th class="text-left">Base Qty.</th>
                          <th class="text-left">Base UoM</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in groupDef" v-bind:key="index">
                          <td>
                            {{ item.AltQty }}
                          </td>
                          <td>
                            {{ item.uomentry.UomName }}
                          </td>
                          <td>=</td>
                          <td>{{ item.BaseQty }}</td>
                          <td>{{ groupItem.ouom.UomName }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <!-- group definition entry modal -->
              <v-dialog width="1050" v-model="defDialog">
                <v-toolbar dense color="accent" dark>
                  <v-toolbar-title>New Group Definition</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click.native="defDialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="3">
                        <v-text-field label="Alt Qty." v-model="defItem.AltQty"></v-text-field>
                      </v-col>

                      <v-col cols="3">
                        <v-autocomplete
                          v-model="defItem.UomEntry"
                          :items="uom"
                          item-text="UomName"
                          item-value="id"
                          label="Alt UoM"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="3">
                        <v-text-field label="Base Qty." v-model="defItem.BaseQty"></v-text-field>
                      </v-col>

                      <v-col cols="3">
                        <v-text-field label="Base UoM" v-if="groupItem.ouom" v-model="groupItem.ouom.UomName" readonly filled></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-btn color="accent" small @click="createugp1">
                          <v-icon left>mdi-content-save</v-icon> Save
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- end -->
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- end of -->

      <snackbar ref="snackbar"></snackbar>
    </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    uomGroups: [],
    gdDialog: false,
    defDialog: false,
    defItem: {},
    groupItem: {},
    groupDef: [],
    uom: [],
    definitions: [],
    headers: [
      { text: "UoM Group Code", value: "UgpCode" },
      { text: "UoM Group Name", value: "UgpName" },
      { text: "Base UoM", value: "ouom.UomName" },
      { text: "Actions", value: "actions", sortable: false }
    ]
  }),
  methods: {
    groupDefinition(data) {
      console.log(data, 'dt');
      this.groupItem = data;
      this.groupDef = data.groupDef;
      this.gdDialog = true;
    },
    newGroup() {
      this.defDialog = true;
    },
    createugp1() {
      const data = {
        'UgpEntry': this.groupItem.id,
        'UomEntry': this.defItem.UomEntry,
        'AltQty': this.defItem.AltQty,
        'BaseQty': this.defItem.BaseQty
      };
      const url = "/createugp1";
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          location.reload();
        })
        .catch(err => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getUom() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/uom`)
        .then(res => {
          self.uom = res.ResponseData;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getUomGroups() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/uomgroup`)
        .then(res => {
          self.uomGroups = res.data;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getUomGroups();
    this.getUom();
  }
};
</script>